.purch-forecasting-main {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  background-color: #fde9c8;
}

.p-main-container-side-container {
  flex-basis: 200px;
  height: 100%;
  display: flex;
  border-right: 1px solid #e0e0e0;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.p-main-container-center-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: flex-start;
  padding-left: 10px;
}

.user-upload-strip {
  width: 100%;
  padding: 15px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results-visualization {
  width: 100%;
  max-width: 140vh; 
  padding: 15px;
  flex-grow: 1;
  box-sizing: border-box;
  display: block;  /* replace flex with block */
  margin: auto;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}

.download-strip {
  width: 100%;
  padding: 15px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
