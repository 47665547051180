.p-output-container-output-container {
  width: 100%;
  height: 545px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fbd6a8;
}
.p-output-container-text {
  color: var(--dl-color-gray-black);
  font-size: 42px;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
}
.p-output-container-textinput {
  color: #fba007;
  width: 900px;
  height: 236px;
  font-size: 18px;
  align-self: center;
  text-align: justify;
  font-family: Lato;
  border-color: #f58408;
  border-width: 3px;
  background-color: #fdf0d8;
}
.p-output-container-root-class-name {
  background-color: #ffe2b0;
}
.clear-button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #078dfb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
}

.output-image {
  max-width: 100%;
  max-height: 80%;
  padding: 10px 20px;
  height: auto;
  align-self: center;
}