.home-main-container-main-container {
  display: flex;
  width: 100%;
  height: 896px;
  position: relative;
}

.home-main-container-int-container {
  display: flex;
  width: 100%;
  height: 896px;
}

.home-main-container-side-container {
  flex-basis: 352px; /* Fixed width for the left sidebar */
  height: 896px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.home-main-container-sidebar {
  width: 100%; /* To fill the parent container */
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  background-color: #edcef7;
}

.home-main-container-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-main-container-text {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-main-container-navlink {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-main-container-text1 {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-main-container-text2 {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-main-container-text3 {
  font-size: 32px;
  font-family: Lato;
}
.home-main-container-lottie {
  width: 300px;
  height: 300px;
}
.home-main-container-center-container {
  flex-grow: 1; /* Makes it expand */
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-main-container-image-container {
  flex-basis: 395px; /* Fixed width for the right sidebar */
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/flowerundersea-900h.png");
}

.home-main-container-above-title {
  width: 100%;
  height: 287px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-main-container-text6 {
  font-size: 42px;
  font-family: Lato;
}

@media(max-width: 767px) {
  .home-main-container-sidebar {
    height: 70vh;
  }
}
