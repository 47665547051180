.p-download-strip-container {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9dcbb;
}

.p-download-strip-select {
  width: 150px;
  height: 40px;
  font-size: 16px;
  margin-right: 20px;
  font-family: Lato;
  border: 3px solid #f58408;
  border-radius: 5px;
  background-color: #fdf0d8;
}

.p-download-strip-button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #078dfb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
}

.p-download-strip-button-error {
  background-color: red;
}

.p-data-viewer-box {
  /* your existing styles */
  background-color: white; /* Set background to white */
}

/* Make header font color more visible, e.g., black */
.p-data-viewer-table th {
  color: black; /* Adjust the font color */
  background-color: white; 
}

/* Standard row alternation color */
.p-data-viewer-table tr:nth-child(odd) {
  background-color: white;
}

.p-data-viewer-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Light gray color for alternating rows */
}
