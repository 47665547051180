.sidebar {
  width: 352px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7cb8e;
}

.nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navlink, .text {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}

.lottie-node {
  width: 300px;
  height: 300px;
}
