.p-menu-container-menu-container {
  flex: 0 0 auto;
  width: auto;
  height: 896px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}
.p-menu-container-sidebar {
  width: 352px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7cb8e;
}
.p-menu-container-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.p-menu-container-navlink {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.p-menu-container-text {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.p-menu-container-text1 {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.p-menu-container-navlink1 {
  font-size: 32px;
  font-family: Lato;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.p-menu-container-text4 {
  font-size: 32px;
  font-family: Lato;
  text-decoration: none;
}
.p-menu-container-lottie-node {
  width: 300px;
  height: 300px;
}
.p-menu-container-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.p-menu-container-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.p-menu-container-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 767px) {
  .p-menu-container-sidebar {
    height: 70vh;
  }
}
