.header-header {
  width: 90%;
  height: 7px;
  display: flex;
  position: relative;
  max-width: 90w; 
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.header-header.home {
  background-color: #d89df5; /* Purple */
}

.header-header.purchase {
  background-color: #FFA500; /* Orange */
}

.header-header.sales {
  background-color: #008000; /* Green */
}

.header-logo {
  height: 2rem;
}
.header-header-navigation-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-login-button-container {
  display: flex;
  margin-left: auto;
}
.header-nav {
  display: flex;
}
.header-login {
  font-family: Lato;
  border-color: #d87005;
  border-width: 2px;
  margin-right: var(--dl-space-space-twounits);
  background-color: rgb(247, 210, 170);
}
@media(max-width: 767px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-nav {
    display: none;
  }
  .header-login-button-container {
    display: none;
  }
}
@media(max-width: 479px) {
  .header-header {
    padding: var(--dl-space-space-unit);
  }
}
