.home-hero-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-hero-about-susanna {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-hero-t-about-susanna {
  font-size: 3rem;
}
.home-hero-text {
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-hero-button {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-hero-button:hover {
  transform: scale(1.02);
}
.home-hero-image {
  width: 45rem;
  margin-top: var(--dl-space-space-twounits);
}
@media(max-width: 991px) {
  .home-hero-hero {
    flex-direction: column;
  }
  .home-hero-about-susanna {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-hero-t-about-susanna {
    text-align: center;
  }
  .home-hero-text {
    text-align: center;
  }
  .home-hero-image {
    width: 80%;
  }
}
@media(max-width: 767px) {
  .home-hero-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-text {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-hero-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-hero-about-susanna {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero-button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
