.p-input-container-input-container {
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  max-height: 350px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f9dcbb;
}
.p-input-container-container {
  width: 100%;
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.p-input-container-heading {
  height: 87px;
  font-size: 42px;
}
.p-input-container-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 266px;
  flex-direction: column;
  justify-content: center;
}
.p-input-container-text3 {
  font-size: 22px;
  font-family: Lato;
}
.p-input-container-textinput {
  color: #fba007;
  width: 494px;
  height: 150px;
  font-size: 18px;
  text-align: justify;
  font-family: Lato;
  border-color: #f58408;
  border-width: 3px;
  background-color: #fdf0d8;
}

.submit-button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #078dfb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
}
