.main-container {
  width: 100vw; 
  min-height: 100vh; 
  display: flex;
  overflow: auto;
  align-items: flex-start; 
  background-color: #fde9c8;
}

.content-container {
  flex: 1; 
  display: flex;
  align-items: flex-start;
  height: 100%;
  background: #fde9c8;
}

.io-container {
  flex: 1; 
  display: flex;
  flex-direction: column;
  height: 100%;
}

.input-section {
  flex: 0.3; 
}

.output-section {
  flex: 0.7;
}
