.p-user-upload-container {
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f9dcbb;
  position: relative;  /* Required for modal overlay */
}

.p-user-upload-heading {
  height: 60px;
  font-size: 42px;
}

.p-user-upload-input-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left; /* Align horizontally */
  gap: 80px; /* Spacing between the buttons */
}

.p-user-upload-button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #078dfb;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.p-user-upload-button:hover {
  background-color: #0574d0;
}

.p-user-upload-dropdown-container {
  position: relative;
  display: inline-block;
}

.p-user-upload-dropdown-item {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: background-color 0.3s ease, transform 0.3s ease, visibility 0.3s, opacity 0.3s ease;
}

.p-user-upload-dropdown-container:hover .p-user-upload-dropdown-item {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.p-user-upload-dropdown-item:hover {
  background-color: #0574d0;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}


.p-user-upload-submit-button {
  background-color: #5F76FA;  /* Changed color to differentiate from other buttons */
}

.p-user-upload-clear-button {
  background-color: #ff6666;  /* Example red color for the "Clear" button */
}


 /* Overlay modal window */


.p-user-upload-settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9
}

.p-user-upload-settings-modal {
  background: #DAF2F7;
  border-radius: 10px;
  height: 300px;
  width: 80%;
  max-width: 500px;
  border: 1px solid #ddd;  /* Border for the modal */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);  /* Shadow for depth */
  overflow: auto;  /* Add scrolling if content overflows */
  min-height: 25%;
  padding: 40px;  /* Increased padding for space */
  position: relative; 
  z-index: 10;
}

.p-user-upload-settings-close {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 12px;
  height: 12px;
  background-color: #FF5F56;  /* macOS close button red color */
  border-radius: 50%;  /* Makes it a circle */
  border: none;
  cursor: pointer;
}

/* Optional: Add a hover effect to give feedback to the user */
.p-user-upload-settings-close:hover {
  background-color: #DD4B3A;  /* Slightly darker shade on hover */
}

.p-user-upload-settings-apply {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #3A5194;  /* Similar styling as other buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
}

/* Optional: Add a hover effect */
.p-user-upload-settings-apply:hover {
  background-color: #066cc5;  /* Slightly darker shade on hover */
}

.input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-input {
  width: 80px; /* or whatever width you prefer */
  margin-right: 10px;
}

.favor-dropdown {
  width: 100px; /* or adjust as needed */
}

/* status */

.status-container {
  width: 15ch;
  height: 36px; /* Adjusted to match the height of your buttons */
  border: none; /* Removed border to match your button style */
  background-color: lightblue;
  border-radius: 5px; /* Rounded edges like your buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default; /* Optional: to indicate this is not a clickable element */
  font-size: 20px; /* Match font size of buttons */
  font-family: Lato; /* Match font family of buttons */
  color: #fff;
  transition: background-color 0.3s ease-in-out; /* Transition effect */
}

.status-container.success {
  background-color: #157F1F;
  transition: background-color 0.3s ease-in-out; /* Transition effect */
}

.status-container.error {
  background-color: #F44336;
  transition: background-color 0.3s ease-in-out; /* Transition effect */
}

@keyframes slideFadeOut {
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(100%); opacity: 0; }
}

.status-container.transitioning {
  animation: slideFadeOut 0.5s forwards;
}

/* To smoothly transition the background color back to blue */
.status-container {
  transition: background-color 0.5s ease-in-out;
}


.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;  /* Assuming this is roughly the width of your Submit button */
  height: 30px; /* Adjust height to your button's height */
}
.spinner {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  animation: ripple 1.5s infinite;
}

.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4285F4; /* Google Blue */
  opacity: 0.6;
  animation: ripple-animation 1.5s infinite;
}

.spinner:after {
  background-color: #34A853; /* Google Green */
  animation-delay: 0.75s;
}

@keyframes ripple {
  0% {
      transform: scale(0.5);
  }
  100% {
      transform: scale(1.2);
      opacity: 0;
  }
}

@keyframes ripple-animation {
  0% {
      transform: scale(0.5);
      opacity: 0.6;
  }
  100% {
      transform: scale(1.2);
      opacity: 0;
  }
}

.status-container.all-selected {
  background-color: #34A853; /* Google Green */
}