.purch-main-container-puch-main-container {
  display: flex;
  width: 100%;
  height: 896px;
  position: relative;
}

.purch-main-container-purch-center-container {
  display: flex;
  flex-grow: 1; /* This will make sure it takes the remaining width */
  height: 896px;
}

.purch-main-container-side-container {
  flex-basis: 200px; /* Assign a fixed width; adjust as needed */
  height: 896px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.purch-main-container-central-panel {
  flex-grow: 1; /* This will make it expand to fill the available space */
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f9dcbb;
}

.purch-main-container-container {
  width: 100%;
  height: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purch-main-container-text08 {
  font-size: 42px;
  font-family: Lato;
}

.purch-main-container-image-container {
  flex-basis: 395px; /* Assign a fixed width; adjust as needed */
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/foxflowers-900h.png");
}

@media(max-width: 767px) {
  .purch-main-container-sidebar {
    height: 70vh;
  }
}
