.p-data-viewer-container {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf0d8;
  min-height: 500px;
  padding: 20px;
}

.p-data-viewer-box {
  width: 100%;
  height: 100%;
  overflow-x: auto; /* Horizontal scroll */
  overflow-y: auto; /* Vertical scroll */
  border: 3px solid #f58408;
  background-color: #f9dcbb;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.p-data-viewer-table {
  width: 100%; 
  padding: 20px;
  border-collapse: collapse;
}

.p-data-viewer-table th, .p-data-viewer-table td {
  padding: 10px;
  border: 1px solid #f58408;
  font-family: Lato;
  padding: 20px;
}

.p-data-viewer-table th {
  background-color: red;
  color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: rgb(164, 199, 255);
  z-index: 1;
}


.p-data-viewer-table-cell-highlighted {
  background-color: #a8daff !important;
}

.p-data-viewer-table tr:nth-child(even) {
  background-color: #fdf0d8;
}

.p-data-not-available {
  font-size: 50px;
  color: #333;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9dcbb;
}
