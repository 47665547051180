.login-button {
  color: #fff; /* White text */
  border: none; /* Remove default button border */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Padding around text */
  font-size: 16px; /* Increase font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Small shadow */
  cursor: pointer; /* Change cursor to pointer when hovering over the button */
  transition: background-color 0.3s ease; /* Smooth transition when changing background color */
}

.login-button.not-logged-in {
  background-color: #4285f4; /* Blue for not-logged-in users */
}

.login-button.not-logged-in:hover {
  background-color: #fbbc05; /* Google's orange color when hovering */
}

.login-button.basic {
  background-color: #4285f4; /* Blue for basic users */
}

.login-button.basic:hover {
  background-color: #fbbc05; /* Google's orange color when hovering */
}

.login-button.advanced {
  background-color: #34a853; /* Green for advanced users */
}

.login-button.advanced:hover {
  background-color: #fbbc05; /* Google's orange color when hovering */
}

.login-button.admin {
  background-color: #ea4335; /* Red for admins */
}

.login-button.admin:hover {
  background-color: #fbbc05; /* Google's orange color when hovering */
}
